export default {
  text: '#222222',
  background: '#fff',
  primary: '#222',
  secondary: '#999',
  sidebar: '#fafafa',
  borderColor: '#dcdcdc',
  menu: '#6b6b6b',
  menuhover: '#ff6200',
  link: '#ff6200',
  linkhover: '#dc2e2e',
  capitalizedtext: '#eb7a34',
  codebackground: '#dc2e2e',
  modes: {
    dark: {
      text: '#a9a9b3',
      background: '#292a2d',
      primary: '#a9a9b3',
      secondary: '#73747b',
      sidebar: '#252627',
      borderColor: '#4a4b50',
      menu: '#ffa500',
      menuhover: '#f9f9f9',
      link: '#ffa800',
      linkhover: '#f9f9f9',
      capitalizedtext: '#ffa500',
      codebackground: 'none',
    }
  }
};
